/**************** banner part css start ****************/
.banner_part{
    position: relative;
    background-color: #dde5e8;
    @include background ('../img/banner_img.png');
    .single_banner_slider{
        .banner_text{
            display: table;
            width: 100%;
            height: 820px;
            .banner_text_iner{
                display: table-cell;
                vertical-align: middle;
            }
            @media #{$tab}{
               height: 550px;
            }
            @media #{$medium_device}{
                height: 650px;
            }
            h5{
                font-size: 24px;
                letter-spacing: 2px;
                @media #{$tab}{
                    font-size: 18px;
                 }
            }
            h1{
                font-size: 100px;
                text-transform: capitalize;
                font-weight: 700;
                line-height: 110px;
                margin: 17px 0 49px;
                @media #{$small_mobile}{
                    font-size: 35px;
                    margin-bottom: 15px;
                    line-height: 1.3;
                }
                @media #{$large_mobile}{
                    font-size: 30px;
                    margin-bottom: 15px;
                    line-height: 1.3;
                }
                @media #{$tab_device}{
                    font-size: 40px;
                    margin-bottom: 15px;
                    line-height: 1.3;
                }
                @media #{$medium_device}{
                    font-size: 40px;
                    margin-bottom: 15px;
                    line-height: 1.4;
                }
                span{
                    color: $btn_bg;
                }
            }
            p{
                font-size: 15px;
                line-height: 1.8;
                font-family: $font_stack_2; 
                margin-bottom: 70px;
                padding-right: 80px;
                @media #{$tab}{
                    padding-right: 0;
                    margin-bottom: 10px;
                 }
                 @media #{$medium_device}{
                    padding-right: 0;
                 }
            }
        }
        .banner_img{
            position: absolute;
            right: 0;
            top: 30%;
        }
    }
    .owl-nav {
        position: absolute;
        right: -20%;
        bottom: 0;
        padding: 35px 80px;
        background-color: #fff;
        @media #{$tab}{
            right: 0;
            padding: 20px 40px;
        }
        @media #{$medium_device}{
            right: 0;
            padding: 20px 40px;
        }
        .owl-prev,
        .owl-next {
            text-transform: capitalize;
            margin: 0 15px!important;
            text-transform: capitalize;
            font-weight: 700 !important;
            
        }
        .owl-prev{
            position: relative;
            &:after{
                position: absolute;
                right: -17px;
                top: 3px;
                width: 1px;
                height: 16px;
                content: "";
                background-color: #000;
            }
        }
    }
    .btn_2{
        box-shadow: none;
        @media #{$tab}{
            .btn_2{
                margin-top: 15px;
            }
         }
    }
    .slider-counter{
        font-size: 100px;
        font-weight: 700;
        color: #cde8eb;
        position: absolute;
        right: -16%;
        top: 38%;
        @media #{$tab}{
            font-size: 40px;
        }
        @media #{$medium_device}{
            font-size: 40px;
        }
    }
    @media #{$tab}{
        padding: 0;
    }
    
     @media #{$medium_device}{
        .banner_img {
            img{
                max-width: 75%;
                float: right;
            }
        }
     }
} 
/**************** hero part css end ****************/
