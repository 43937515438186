/***************** product list css *******************/
.product_list {
    .section_tittle {
        text-align: left !important;

        h2 {
            text-transform: capitalize;
        }

        span {
            color: #adadad;
            font-size: 20px;
            font-weight: 700;
            margin-left: 60px;
        }
    }

    

    .owl-nav {
        position: absolute;
        right: 0;
        top: -62px;
        @media #{$tab}{
            right: 0;
            padding: 20px 40px;
        }
        @media #{$medium_device}{
            right: 0;
            padding: 20px 40px;
        }
        .owl-prev,
        .owl-next {
            text-transform: capitalize;
            margin: 0 15px!important;
            text-transform: capitalize;
            font-weight: 700 !important;
            
        }
        .owl-prev{
            position: relative;
            &:after{
                position: absolute;
                right: -17px;
                top: 3px;
                width: 1px;
                height: 16px;
                content: "";
                background-color: #000;
            }
        }
    }
}
.single_product_item {
    border-radius: 5px;
    margin-bottom: 20px;
    @include transform_time(0.5s);
    img{
        @media #{$tab}{
            width: 100%;
        }
        @media #{$medium_device}{
            width: 100%;
        }
    }
    .single_product_text {
        padding: 32px 0;
        background-color: $white_color;
        @include transform_time(0.5s);

        h4 {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 14px;
        }

        h3 {
            font-weight: 300;
            font-size: 18px;
        }

        a {
            color: $primary_color;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            display: block;
            margin-top: 10px;
            opacity: 0;
            visibility: hidden;
            @include transform_time(0.5s);

            i {
                float: right;
                font-size: 18px;
                line-height: 26px;
                color: $black_color;
            }
        }
    }

    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(0, 23, 51, 0.09);

        .single_product_text {
            padding: 32px 32px;
        }

        a {
            opacity: 1;
            visibility: visible;
        }
    }
}
.single_category_product{
    position: relative;
    overflow: hidden;
    margin-bottom: 35px;
    img{
        width: 100%;
    }
    .category_social_icon{
        position: absolute;
        right: -30px;
        bottom: 100px;
        visibility: hidden;
        opacity: 0;
        @include transform_time(.5s);
        a{
            width: 50px;
            height: 50px;
            display: inherit;
            background-color: $primary-color;
            border-radius: 50%;
            line-height: 50px;
            text-align: center;
            color: #fff;
            margin-right: 10px;
            margin-top: 5px;

        }
    }
    &:hover{
        .category_social_icon{
            position: absolute;
            right: 11px;
            bottom: 100px;
            visibility: visible;
            opacity: 1;
        }
    }
    .category_product_text{
        text-align: center;
        margin-top: 23px;
        h5{
            font-size: 16px;
            color: $black_color;
            margin-bottom: 0;
        }
        p{
            font-size: 18px;
            font-weight: 600;
            color: #606060;
        }
    }
}